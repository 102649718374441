<template>
    <div v-if="note" class="container-fluid">
    	<h2 class="mb-4">{{ note.note_title }}</h2>

		<div class="row col-12 mb-2">
			<span v-html="cleanHTML(note.note_comment)"></span>
		</div>

		<div v-for="file in files" :key="file.files_id" class="row">
			<div class="col-12">
				<template v-if="isImgRendable(file)">
					<div class="container-note note-image">
						<img :src="sourceFile(file)" />
					</div>
				</template>
				<template v-if="isVideoRendable(file)">
					<div class="container-note note-video">
						<span v-if="working" class="d-flex justify-content-center">
							<font-awesome-icon :icon="['fal', 'spinner']" spin />
						</span>
						<video v-else controls :src="sourceFile(file)"></video>
					</div>
				</template>
				<template v-if="isAudioRendable(file)">
					<div class="container-note note-audio">
						<audio :src="sourceFile(file)" controls></audio>
					</div>
				</template>
				<template v-else-if="isIframeRendable(file)" class="mb-4">
					<iframe class="w-100" :src="sourceFile(file)" height="500px" />
				</template>
				<template v-if="isEmbeddRendable(file)">
					<video v-if="sourceFile(file).substring(sourceFile(file).length - 4) == '.mp4'" controls :src="sourceFile(file)"></video>
					<!-- <iframe :src="sourceFile(file)" width="800px" height="500px" frameborder="0" allow="accelerometer; encrypted-media; gyroscope" allowfullscreen /> -->
					<a v-else :href="sourceFile(file)" target="blank">{{ file.files_url }}</a>
				</template>
				<template v-if="isTextRendable(file)">
					{{ sourceFile(file) }}
				</template>
			</div>
		</div>

		<div v-if="isEmbedded" class="row">
			<div class="text-center mt-3">
                <button @click="closePreview" class="btn btn-secondary rounded-pill mr-4">
                    <font-awesome-icon :icon="['fal', 'times']" /> {{ $t('global.close') }}
                </button>
            </div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import File from '@/mixins/File.js'
    import Notes from '@/mixins/Notes.js'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterNotes from '@/mixins/shutters-manager/Notes.js'
	import xss from 'xss'

	export default {
        name: "NotePreview",
        mixins: [File, Notes, Shutter, ShutterNotes],
		props: {
			note_id: { type: Number, default: 0 },
		},
		data () {
			return {
				note: null,
				files: null,
				working: true,
			}
        },
		computed: {
			isEmbedded() {
				return this.shutterPanel().getShutterIndex('note-preview') > 0
			}
		},
        created() {
			this.preinit_component()
        },
        methods: {
			preinit_component() {
				const promises = [
					this.getNote(this.note_id).then(res => this.note = res),
					this.getNoteFiles(this.note_id).then(res => this.files = res)
				]

				Promise.all(promises)
					.finally(_ => this.working = false)
			},
			closePreview() {
				this.shutterPanel().keepLast()
			},
			cleanHTML(input) {
				return xss(input)
			}
        }
    }
</script>
